import { mdast, md } from '@readme/markdown';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import DateLine from '@ui/DateLine';
import RDMD from '@ui/RDMD';

import { LOG } from './shapes';
import classes from './style.module.scss';

function useExcerptedRDMD(body) {
  try {
    const node = mdast(body);
    node.children = [node.children[0]];
    return md(node);
  } catch (e) {
    return '';
  }
}

const ChangelogExcerpt = ({ exactDate, log, showAuthor, typeIconClasses }) => {
  const excerpt = useExcerptedRDMD(log.body);
  return (
    <div className={`${classes.ChangelogPost} ${classes.ChangelogPostExcerpt}`}>
      {!!log.type && (
        <div className={classes.ChangelogPost_type}>
          {!!typeIconClasses[log.type] && <span className={`${typeIconClasses[log.type]} ${classes.ChangelogIcon}`} />}
          {log.type}
        </div>
      )}
      <h1 className={`${classes.ChangelogPost_title} ${classes.ChangelogPost_titleexcerpt}`}>
        <Link
          to={{
            pathname: `/changelog/${log.slug}`,
            state: { fromChangelogList: true },
          }}
        >
          {log.title}
        </Link>
      </h1>
      <DateLine
        className={`${classes.ChangelogPost_date} ${classes.ChangelogPost_noicon}`}
        icon={exactDate ? '' : 'icon-clock'}
        suffix={!!showAuthor && !!log.user && `by ${log.user?.name}`}
        time={log.createdAt}
        {...(exactDate ? { fnsFormat: 'PPP' } : {})}
      />
      <RDMD className={classes.ChangelogPost_text} Tag="section">
        {excerpt}
      </RDMD>
    </div>
  );
};

ChangelogExcerpt.propTypes = {
  exactDate: PropTypes.bool,
  log: PropTypes.shape(LOG),
  showAuthor: PropTypes.bool,
  typeIconClasses: PropTypes.object,
};

export default ChangelogExcerpt;
